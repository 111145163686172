import { createRef, useEffect, useState } from "react";
import * as QRCode from 'easyqrcodejs'
import "./PlaqueV2.css";
// import logo from "../assets/svgs/logo-light.svg";

const Plaque = ({ artist, artTitle, qrCodeUrl, height, plaque, plaqueSize = 'md' }) => {
    const codeRef = createRef();

    const plaqueSizeClass = `plaque-container--${plaqueSize}`

    useEffect(() => {
        if (codeRef.current) {
            new QRCode(codeRef.current, {
                text: qrCodeUrl,
                width: height,
                height: height,
                colorDark : "#ffffff",
                colorLight : "#000000",
                correctLevel : QRCode.CorrectLevel.L,
                drawer: 'svg'
            });
        }
    }, [qrCodeUrl, plaque]);

    const handleClick = () => {
        // if plaque has qr code, open link
        if (qrCodeUrl) {
            window.open(qrCodeUrl, '_blank');
        }
    };

    return (

        <div className={`plaque-container ${plaqueSizeClass}`} onClick={handleClick}>
            {((plaque === "info_qr" || plaque === "qr_only") && qrCodeUrl) && (
                <div className="qc-container">
                    <div ref={codeRef}></div>
                </div>
            )}
            {plaque !== 'qr_only' && (
                <div className="info-container" >
                    <span className="art-author">
                        {artist || "Unknown"}
                    </span>
                    <span className="art-title">
                        {artTitle || ""}
                    </span>
                </div>
            )}
        </div>
    );
}

export default Plaque;
