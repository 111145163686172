import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import UnsupportedFormat from "./UnsupportedFormat";
import logoLight from "../assets/svgs/logo-light.svg";
import "./Artwork.css";
import Plaque from "./PlaqueV2";
import Image from "./Image";
// import { artworkWrapHeight } from "../lib/util";

// Currently the plaque is just 10% of the screen height. We should come up with some rules for this,
// but can wait and see what makes sense visually.
const plaqueHeightPercent = 0.075;

const Artwork = ({
    playFullVideos,
    onArtworkCanAdvance,
    onArtworkShouldAdvance,
    artwork,
    show,
    optimizeMedia = true,
    rotation,
    plaque,
    plaqueSize = 'md',
    transition,
    display
}) => {
    const [showLoading, setShowLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [artworkClassName, setArtworkClassName] = useState(
        show ? "Artwork" : "Artwork Artwork--hidden"
    );
    const [willUnload, setWillUnload] = useState(false);

    const videoRef = useRef(null);

    function onStaticReady() {
        onReady()
    }
    function onReady() {
        setLoaded(true);
    }

    useEffect(() => {
        // Show loading indicator if it hasn't loaded within a certain time
        setTimeout(() => {
            setShowLoading(true);
        }, 1000);
    }, []);

    useEffect(() => {
        // Reduce CPU usage by setting visibility to hidden when
        // artwork is not being shown.

        if (show) {
            setArtworkClassName("Artwork");
        } else {
            // Add delay so that it does not interfere with the exit animation.
            setTimeout(() => {
                setArtworkClassName("Artwork Artwork--hidden");
            }, 1000);
        }
    }, [show]);

    // display option (fit/fill) comes from artwork object unless overridden by display prop
    if (display) {
        artwork.display = display
    }

    // media optimization logic
    const { innerHeight, innerWidth } = window;
    const effectiveHeight = rotation === 0 || rotation === 180 ? innerHeight : innerWidth;
    //   const maxDim = Math.max(innerHeight, innerWidth);
    //   const plaqueHeightPixels = maxDim * plaqueHeightPercent;
    const plaqueHeightPixels = 160;
    let mediaUrl
    // if the user has disabled optimization, just load the original file
    if (!optimizeMedia) {
        mediaUrl = artwork?.mediaUrl_original
    } else {
        // for images, we'll load up to 4k version
        if (artwork.mediaType === 'image') {
            if (effectiveHeight <= 720) {
                mediaUrl = artwork.mediaUrl_720
            } else if (effectiveHeight <= 1080) {
                mediaUrl = artwork.mediaUrl_1080
            } else {
                mediaUrl = artwork.mediaUrl_4k
            }
        }
        // for video, we'll load up to 1080 version
        if (artwork.mediaType === 'video' || artwork.mimeType === 'image/gif') {
            if (effectiveHeight <= 720) {
                mediaUrl = artwork.mediaUrl_720
            } else {
                mediaUrl = artwork.mediaUrl_1080
            }
        }
    }

    if (!mediaUrl) {
        mediaUrl = artwork.artworkUrl
    }

    // add artwork bg color to style
    const artworkStyle = {
        backgroundColor: artwork.bgColor,
    }

    // determine which player to use
    let player = 'unknown'
    // if the artwork is a video or a gif w/ optimization enabled (and an mp4 media url), use the video player
    if (artwork.mediaType === 'video' || (artwork.mimeType === 'image/gif' && optimizeMedia && artwork.mediaUrl_1080?.includes('mp4'))) {
        player = 'video'
    } else if (artwork.mediaType === 'image') {
        player = 'image'
    } else if (artwork.mediaType === 'website') {
        player = 'website'
    }

    useEffect(() => {
        if (show && videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
            setWillUnload(false)

            // If playFullVideos is enabled, set can advance to false until
            // the video is complete.
            if (playFullVideos) {
                onArtworkCanAdvance(false)
            } else {
                // setting to true immediately upon starting video means that when then
                // interval time is hit in Frame.js the logic there will advance to the next
                // Artwork
                onArtworkCanAdvance(true)
            }
        }

        // If this isn't a video, allow the collection to advance to the next artwork when
        // interval time is hit
        if (show && artwork.mediaType !== 'video') {
            onArtworkCanAdvance(true)
        }

        // Audio fading - audio isn't an option since it kills autoplay
        // if (!show && videoRef.current) {
        //   const fadeAudio = setInterval(() => {
        //     console.log('fading audio', videoRef.current.volume)
        //     if (videoRef.current.volume > 0.06) {
        //       videoRef.current.volume = videoRef.current.volume - 0.05
        //     }

        //     if (videoRef.current.volume <= 0.1) {
        //       videoRef.current.volume = 0;
        //       videoRef.current.pause()
        //       clearInterval(fadeAudio);
        //     }
        //   }, 100);
        // }
    }, [show])

    // When the transition out is complete, pause videos so they aren't playing
    // in the background (should already have visibility = 0)
    const handleOnExited = (e) => {
        if (videoRef.current) {
            videoRef.current.pause()
        }
    }

    const handleTimeUpdated = (e) => {
        const duration = e.target.duration
        const currentTime = e.target.currentTime
        // when nearing end and not already set to advance, allow transition to begin
        if (!willUnload && duration - currentTime < 1.5) {
            setWillUnload(true)
            onArtworkShouldAdvance(true)
        }
    }

    return (
        <div className={artworkClassName}>
            <div
                className={
                    "artwork-loading" +
                    (!loaded && showLoading ? " artwork-loading--show" : "")
                }
            >
                <img
                    className="artwork-loading-logo pulse"
                    src={logoLight}
                    alt="loading..."
                />
            </div>

            <CSSTransition
                in={loaded && show}
                classNames={
                    // "artwork-wrap--" + (fadeToBlack ? "fade2black" : "fade")
                    "artwork-wrap--" + transition
                }
                timeout={2000}
                onExited={handleOnExited}
            >
                <div className="artwork-wrap" style={artworkStyle}>
                    {player === 'image' && (
                        <Image
                            alt={artwork.title}
                            src={mediaUrl}
                            onLoad={onStaticReady}
                            style={{
                                objectFit: artwork?.display === "fit" ? "contain" : "cover",
                            }}
                        />
                    )}
                    {player === 'video' && (
                        <video
                            ref={videoRef}
                            className="video"
                            // autoPlay
                            muted
                            playsInline
                            loop={!playFullVideos}
                            preload="auto"
                            src={mediaUrl}
                            onCanPlayThrough={onReady}
                            // onEnded={onArtworkCanAdvance}
                            onTimeUpdate={handleTimeUpdated}
                            style={{
                                objectFit: artwork?.display === "fit" ? "contain" : "cover",
                            }}
                        />
                    )}
                    {player === 'website' && (
                        <iframe title="website" className="website" src={mediaUrl} onLoad={onReady} />
                    )}
                    {player === 'unknown' && (
                        <UnsupportedFormat title={artwork.title} onReadyHandler={onReady} />
                    )}
                </div>

            </CSSTransition>

            {/*  create a plaque for each artworks  */}

            {
                ((loaded && plaque && plaque !== 'none') && (artwork?.creatorUsername || artwork?.title || artwork?.qrCodeUrl)) &&
                <Plaque artist={artwork?.creatorUsername} artTitle={artwork?.title} qrCodeUrl={artwork?.qrCodeUrl} height={plaqueHeightPixels} plaque={plaque} plaqueSize={plaqueSize} />
            }
        </div>
    );
};

export default Artwork;
