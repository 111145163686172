/* global _android */
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";
import { hasArtworkChanged, hasFrameDataChanged, getFrameDataArtworks, /*toggleFullscreen*/ 
shuffleArray} from "./lib/util";
import Artwork from "./components/Artwork";
import PairingScreen from "./components/PairingScreen";
import StatusScreen from "./common/StatusScreen";
import Controls from "./components/Controls";
import { useInterval } from "./lib/use-interval";
import "./Frame.css";

import analytics from './services/analytics'
import deviceStats from './services/device-stats'
import TrialModeBox from "./components/TrialModeBox";

const ENDPOINT = process.env.REACT_APP_API_BASE_URL || "http://localhost:1337";
const ARTWORKS_TO_PRELOAD = 1;
const LOADING_FRAME = "LOADING_FRAME";
const FRAME_NOT_FOUND = "FRAME_NOT_FOUND";
const FRAME_DELETED = "FRAME_DELETED";
const WAITING_FOR_ARTWORK = "WAITING_FOR_ARTWORK";
const WAITING_FOR_PAIRING = "WAITING_FOR_PAIRING";
const STORAGE_LIMIT_REACHED = "STORAGE_LIMIT_REACHED";
const DISPLAYING_ARTWORK = "DISPLAYING_ARTWORK";
const TRIALING_MODE = 'trialing'
analytics.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '')

function Frame() {
    const [status, setStatus] = useState(LOADING_FRAME);
    const [devicePairingCode, setDevicePairingCode] = useState("");
    // deviceData is the metadata about this device, including OS, browser, memory, etc.
    const [deviceData, setDeviceData] = useState(null)
    const [frameData, setFrameData] = useState(null);
    const [artworks, setArtworks] = useState([]);
    const [subStatus, setSubStatus] = useState('')
    const [subPeriodEnd, setSubPeriodEnd] = useState(null)

    const [artworkIndex, setArtworkIndex] = useState(0);
    const [preloadIndex, setPreloadIndex] = useState(ARTWORKS_TO_PRELOAD);
    const [artworkStarted, setArtworkStarted] = useState(new Date());
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isConnected, setIsConnected] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [showCursor, setShowCursor] = useState(false);

    // Initial state should be set by frame's playFullVideos setting
    // const playFullVideos = true
    const [playFullVideos, setPlayFullVideos] = useState(false)
    const [transition, setTransition] = useState("fadeoutin")
    const [canAdvance, setCanAdvance] = useState(true)
    const [shouldAdvance, setShouldAdvance] = useState(false)

    const artworkChanged = useRef(false);

    const socket = useRef();

    const timerRef = useRef(0);

    // device ID
    let deviceId = localStorage.getItem("zf_deviceId");
    if (!deviceId) {
        // generate a uuid
        const deviceId = uuidv4();
        localStorage.setItem("zf_deviceId", deviceId);
    }

    let initialSlug = window.location.pathname.substring(1) || "";
    if (!initialSlug) {
        // Load pairing code from localStorage
        initialSlug = localStorage.getItem("slug");
        if (initialSlug) {
            window.history.pushState(null, "Zeroframe", `/${initialSlug}`);
        }
    } else {
        localStorage.setItem("slug", initialSlug);
    }
    const [slug, setSlug] = useState(initialSlug);

    // If the frame connection is lost or there's a network issue, show the disconnected indicator
    const showDisconnected = !(isOnline && isConnected);
    const preLoadedArtworks = artworks.length
        ? artworks.slice(0, preloadIndex)
        : [];
    const doReset = () => {
        setStatus(WAITING_FOR_PAIRING);
        analytics.track('display: reset', {
            frameId: frameData?.id,
            accountId: frameData?.account?.id
        }).then(() => {
            localStorage.removeItem("slug");
            if (window._android) {
                window._android.reload()
            } else {
                window.history.pushState({}, "Zeroframe", "/");
                window.location.href = '/';
            }
        })
        analytics.flush()
    }

    // On initial load, load and set device stats
    useEffect(() => {
        deviceStats.getStorage().then(({ usage, quota }) => {
            deviceStats.getDeviceLocationData().then(loc => {
                const screenStats = deviceStats.getScreenStats()
                const dd = deviceStats.getDeviceData()
                const deviceData = {
                    screenWidth: screenStats.w,
                    screenHeight: screenStats.h,
                    screenResolution: screenStats.resolution,
                    screenRatio: screenStats.ratio,
                    screenOrientation: screenStats.orientation,
                    storageUsage: usage,
                    storageQuota: quota,
                    country: loc.country,
                    region: loc.region,
                    city: loc.city,
                }
                Object.assign(deviceData, dd)
                analytics.track('display: loaded', {
                    screenWidth: screenStats.w,
                    screenHeight: screenStats.h,
                    screenResolution: screenStats.resolution,
                    screenRatio: screenStats.ratio,
                    screenOrientation: screenStats.orientation,
                    storageUsage: usage,
                    storageQuota: quota,
                    status: initialSlug ? 'paired' : 'unpaired'
                })
                setDeviceData(deviceData)
                // socket.current.emit('device stats', deviceData)
            })
        })
    }, [])

    // Useful for debugging
    // useEffect(() => {
    //   console.log("frame status", status);
    // }, [status]);

    // Update when frame data changes
    useEffect(
        () => {
            if (!frameData) return;


            // Check if the artwork size is too big!
            const frameDataArtworks = getFrameDataArtworks(frameData);
            
            for (let i = 0; i < frameDataArtworks.length; i++) {
                const aw = frameDataArtworks[i];
                // for testing, set override env var to low number, e.g. 2000000 (2mb)
                const quotaLim = process.env.REACT_APP_STORAGE_QUOTA_OVERRIDE ? parseInt(process.env.REACT_APP_STORAGE_QUOTA_OVERRIDE) : deviceData.storageQuota * .75;
                if (aw.source === 'upload' && parseInt(aw.fileSize) > quotaLim) {
                    analytics.track('display: storage limit reached', {
                        deviceId,
                        frameId: frameData.id,
                    })
                    setStatus(STORAGE_LIMIT_REACHED);
                    delete frameData.artwork
                    delete frameData.artworkCollection

                    // if we want to notify the user in the dashboard, we could emit an event here
                    // socket.current.emit('artwork too big', aw)
                    return
                }
            }

            // Set new artworks to be displayed
            const newArtworks = []
            if (frameData?.artwork) {
                newArtworks.push(frameData.artwork);
                setTransition('fadeoutin')
                setPlayFullVideos(false)
            }
            const artworkCollection = frameData?.artworkCollection
            if (artworkCollection?.artworks) {
                setTransition(artworkCollection?.transition || 'crossfade')
                setPlayFullVideos(artworkCollection?.playFullVideos || false)
                setArtworkStarted(new Date())
                if(artworkCollection?.randomizeOrder){
                    const newArt =shuffleArray(artworkCollection?.artworks)
                    newArtworks.push(...newArt.map(a => a.artwork));
                  }else{
                    newArtworks.push(...artworkCollection.artworks.sort((a, b) => a.position > b.position ? 1 : -1).map(a => a.artwork));
               }
        }

            // If the artwork to display hasn't been updated, no need to continue here
            if (!artworkChanged.current) {
                if (newArtworks.length === 0) {
                    setStatus(WAITING_FOR_ARTWORK);
                }
                return;
            }

            let timeout = 0;
            if (status === DISPLAYING_ARTWORK) {
                // Fade out displayed artwork before showing updated
                // artwork
                setArtworkIndex(-1);
                setCanAdvance(true)
                timeout = 2000;
            }


            setTimeout(() => {
                setArtworkIndex(0);
                setPreloadIndex(ARTWORKS_TO_PRELOAD);
                setArtworks(newArtworks);
                setStatus(
                    newArtworks.length ? DISPLAYING_ARTWORK : WAITING_FOR_ARTWORK
                );
            }, timeout);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [frameData]
    );

    useEffect(() => {
        // console.log("artworkIndex", artworkIndex, artworks[artworkIndex]);

        // update preload index,
        // it should never decrement
        if (artworkIndex !== -1) {
            setPreloadIndex(
                Math.max(preloadIndex, artworkIndex + ARTWORKS_TO_PRELOAD)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artworkIndex, artworks]);

    // Setup artwork pagination
    useInterval(() => {
        // Only paginate if we're displaying something
        if (status !== DISPLAYING_ARTWORK) return;

        // Change artworks when display time has passed the interval
        const now = new Date();
        const timeDisplayed = (now - artworkStarted) / 1000;
        const interval = frameData?.artworkCollection?.interval || Infinity;
        // console.log(artworkIndex, timeDisplayed, canAdvance, shouldAdvance)
        if ((timeDisplayed >= interval && canAdvance) || shouldAdvance) {
            const newIndex =
                artworkIndex < artworks.length - 1 ? artworkIndex + 1 : 0;
            setArtworkIndex(newIndex);
            setArtworkStarted(now);
            setShouldAdvance(false)
        }

        // Check every 1 second
    }, 1000);

    // run on mount
    useEffect(() => {
        // setup network handling
        window.addEventListener("online", () => {
            console.log("[] online");
            setIsOnline(true);
        });
        window.addEventListener("offline", () => {
            console.log("[] offline");
            setIsOnline(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onToggleControls = (e) => {
        setShowControls(!showControls);
        // this logic should happen outside this function, leaving in for reference
        // if (e.key === "Enter" || e.type === "click") {
        //     // toggleFullscreen();
        // }
    }

    useEffect(() => {
        // hide cursor after 3 seconds
        const onMouseMove = (e) => {
            if (!showCursor) {
                setShowCursor(true);
            }
            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
                setShowCursor(false);
            }, 3000)
        }
        window.addEventListener("mousemove", onMouseMove);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        }
    }, [showControls, showCursor])

    // run on mount and when slug changes, setup socket connection
    useEffect(() => {
        // wait until device data is loaded to do the frame data stuffs
        if (!deviceData) return;

        function updateFrameData(newFrameData) {
            // Only update the frame data if it differs from
            // the existing frame data. This is because Socket.IO
            // will trigger the "frame connected" event:
            // 1. when initially connecting
            // 2. after a network reconnection
            //
            // See also:
            // https://reactjs.org/docs/hooks-reference.html#functional-updates
            const { subStatus, subPeriodEnd } = newFrameData
            if (subPeriodEnd && subStatus) {
                setSubStatus(subStatus);
                setSubPeriodEnd(subPeriodEnd);
            }
            setFrameData((prevFrameData) => {
                artworkChanged.current = hasArtworkChanged(prevFrameData, newFrameData)
                return hasFrameDataChanged(prevFrameData, newFrameData)
                    ? newFrameData
                    : prevFrameData;
            });
        }

        setIsConnected(false);

        console.log('version', process.env.REACT_APP_VERSION)
        const query = {
            deviceId,
            version: process.env.REACT_APP_VERSION
        }
        if (slug) {
            query.slug = slug
        }

        // Initiate socket connection
        socket.current = io(ENDPOINT, {
            query,
            withCredentials: true
        });

        if (!slug) {
            // No slug, show the pairing screen
            setStatus(WAITING_FOR_PAIRING);

            socket.current.on("device connected", (data) => {
                console.log("[] device connected");
                setIsConnected(true);
                const device = JSON.parse(data)
                setDevicePairingCode(device.pairingCode);

                // use screenResolution as a proxy to determine whether we've already sent device stats,
                // since we only want to send them once
                if (!device.screenResolution) {
                    console.log('[^] device stats', deviceData)
                    socket.current.emit('device stats', deviceData)
                }
            })

            socket.current.on("device paired", (data) => {
                const parsedData = JSON.parse(data)
                console.log("[] device paired", parsedData);
                localStorage.setItem("slug", parsedData.slug);
                analytics.track('display: paired', {
                    frameId: parsedData.id,
                    accountId: parsedData.account.id
                })
                window.history.pushState(null, "Zeroframe", `/${parsedData.slug}`);
                setSlug(parsedData.slug)
                setDevicePairingCode("")
                setIsConnected(true);
                updateFrameData(parsedData);
            })
        }

        // on initial connect set the frameData
        socket.current.on("frame connected", (data) => {
            console.log("[] frame connected");
            setIsConnected(true);
            updateFrameData(JSON.parse(data));

        });

        socket.current.on("frame updated", (data) => {
            console.log("[] frame updated");
            const frameParsedData = JSON.parse(data);
            // const {subStatus, subPeriodEnd} = frameParsedData
            // setSubStatus(subStatus);
            // setSubPeriodEnd(subPeriodEnd);
            updateFrameData(frameParsedData);
        });
        socket.current.on("frame deleted", (data) => {
            socket.current.disconnect();
            setSlug("");
            localStorage.removeItem("slug");
            window.history.pushState({}, "Zeroframe", "/");
            setStatus(WAITING_FOR_PAIRING);
            // setStatus(FRAME_NOT_FOUND);
        });
        // In case we need to trigger a refresh remotely
        socket.current.on("frame reloaded", (data) => {
            if (window._android) {
                window._android.reload()
            } else {
                document.location.reload();
            }
        });
        // If the frame pairingCode can't be found on the server, show an error
        socket.current.on("frame not found", (data) => {
            // setStatus(FRAME_NOT_FOUND);
            setSlug("");
            localStorage.removeItem("slug");
            window.history.pushState({}, "Zeroframe", "/");
            setStatus(WAITING_FOR_PAIRING);
        });
        socket.current.io.on("reconnect", (attempt) => {
            console.log("[] frame reconnected");
            setIsConnected(true);
        });
        socket.current.on("disconnect", (reason) => {
            console.log("[] frame disconnected", reason);
            setIsConnected(false);
        });

        return () => {
            socket.current.disconnect();
        };
    }, [slug, deviceData]);

    // The Artwork might let us know that it's ok to advance
    const onArtworkCanAdvance = (val) => {
        setCanAdvance(!!val)
    }

    // The Artwork can also let us know that we SHOULD advance now
    const onArtworkShouldAdvance = (val) => {
        if (playFullVideos) {
            setShouldAdvance(!!val)
        } else {
            setShouldAdvance(false)
        }
    }


    let className = "Frame";
    if (frameData?.rotation === 90) {
        className += " Frame--rotate-right";
    } else if (frameData?.rotation === 270) {
        className += " Frame--rotate-left";
    } else if (frameData?.rotation === 180) {
        className += " Frame--rotate-180";
    }

    className += showCursor ? "" : " Frame--hide-cursor";

    return (
        <>
            {
                (subStatus === TRIALING_MODE && status === DISPLAYING_ARTWORK) && <TrialModeBox subPeriodEnd={subPeriodEnd} />
            }
            <div className={className} onClick={onToggleControls}>

                <div
                    className={
                        "disconnected-indicator" +
                        (showDisconnected ? " disconnected-indicator--show pulse" : "")
                    }
                ></div>
                <StatusScreen
                    show={status === STORAGE_LIMIT_REACHED}
                    frameName={frameData?.name}
                    warningCopy={(
                        <>
                            <span>This device doesn't have enough internal storage available to display the pushed media.</span>
                            <p></p>
                            <span>Try updating the browser, and/or reducing the media file size.</span>
                        </>
                    )}
                />

                <StatusScreen
                    show={status === WAITING_FOR_ARTWORK}
                    waitingForArtwork
                    frameName={frameData?.name}
                />

                <StatusScreen
                    show={[FRAME_DELETED, FRAME_NOT_FOUND].includes(status)}
                    frameNotFound
                />

                <PairingScreen
                    show={status === WAITING_FOR_PAIRING}
                    pairingCode={devicePairingCode}
                />
                {status === DISPLAYING_ARTWORK && (
                    preLoadedArtworks.map((artwork, index) => (
                        <Artwork
                            artwork={artwork}
                            show={index === artworkIndex}
                            key={artwork.id}
                            optimizeMedia={frameData?.optimizeMedia}
                            plaque={frameData?.plaque}
                            plaqueSize={frameData?.plaqueSize}
                            rotation={frameData?.rotation}
                            transition={transition}
                            onArtworkCanAdvance={onArtworkCanAdvance}
                            onArtworkShouldAdvance={onArtworkShouldAdvance}
                            playFullVideos={playFullVideos}
                            // if set, frames can override the display option for all artworks
                            display={frameData?.display}
                        />
                    ))
                )
                }
                <Controls
                    show={showControls}
                    doReset={doReset}
                    isConnected={!showDisconnected}
                    frameName={frameData?.name}
                />
            </div>
        </>
    );
}

export default Frame;
